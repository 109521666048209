.Login {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 100px;
      line-height: 48px;
      font-size: large;
      color: aliceblue;
      margin: 20px;
    }

    .title {
      font-size: 22px;
      color: #444444;
    }
  }

  .body {
    height: 400px;
    width: 400px;
    background-color: #ffffff;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 6px;
    padding: 26px 26px 20px ;

    .qr-code {
      height: 350px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .tip-item {
      font-size: 12px;
      text-align: center;
      color: #1677ff;
    }
  }

}
@primary-color: #1DA57A;