.Dashboard {
  padding: 16px;

  //表头样式
  .ant-table-thead > tr > th {
    font-size: small;
    height: 10px;
    line-height: 10px; /* 设置表头行高为 50 像素 */
  }

  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .one {
      flex: 1;
      min-width: 250px;
      margin: 0 10px 10px 0;
      padding: 15px;
      height: 130px;
      border-radius: 8px;
      background-color: white;

      .title {
        color: #999999;
        font-size: 16px;
      }

      .value {
        font-size: 32px;
        color: #444444;
        line-height: 60px;
        text-align: center;
        vert-align: middle;
      }

      .detail {
        color: #999999;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        border-top: 1px solid #eeeeee;
        padding-top: 5px;
        div {
          flex: 1;
          line-height: 20px;
        }
      }
    }
  }

  .mid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .trend {
      flex: 2;
      min-width: 300px;
      margin: 0 10px 10px 0;
    }

    .list {
      flex: 1;
      min-width: 250px;
      margin: 0 10px 10px 0;
    }
  }

  .btm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .system {
      flex: 1;
      min-width: 300px;
      margin: 0 10px 10px 0;
    }

    .area {
      flex: 1;
      min-width: 300px;
      margin: 0 10px 10px 0;
    }
  }

  .card {
    height:350px;
    background-color: white;
    border-radius: 8px;
    padding: 10px 24px 24px 24px;
  }

  .my-row {
    height: 10px;
    line-height: 10px;
    font-size: small;
  }

  .ant-table-cell {
    padding: 8px !important;
  }

  .list-line {
    display: flex;
    flex-direction: row;
    line-height: 26px;

    .rank {
      width: 30px;
    }

    .name {
      flex: 1;
    }

    .value {
      width: 60px;
    }
  }
}
@primary-color: #1DA57A;